import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ShowLoading,
  HideLoading,
} from "../../.././redux/loaderSlice/loaderSlice";
import { getAllExams, getAllExamsEn } from "../../../apicalls/exmas";
import {Helmet} from "react-helmet";
import PageTitle from "../../../components/PageTitle";
import styled from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import azeFlag from '../../../asset/f/aze.png'
import enFlag from '../../../asset/f/uk.png'
const Quizz = () => {
  const [exams, setExams] = useState([]);
  const [examsen, setExamsen] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users);
  const [examName, setExamName] = useState("");
  const { mode } = useSelector((state) => state.darkMode);
  const handleChange = (event) => {
    setExamName(event.target.value);
  };
  const getExams = async () => {
    try {
      dispatch(ShowLoading());
      const response = await getAllExams();
      if (response.success) {
        setExams(response.data);
      } else {
        message.error(response.message);
      }
      dispatch(HideLoading());
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };
  const getExamsEn = async () => {
    try {
      dispatch(ShowLoading());
      const response = await getAllExamsEn();
      if (response.success) {
        setExamsen(response.data);
      } else {
        message.error(response.message);
      }
      dispatch(HideLoading());
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };
  useEffect(() => {
    getExams();
    getExamsEn();
  }, []);
  // console.log('imtaha',exams);
  return (
    <div className={mode ? styled.quizz_body : styled.dark_quizz_body}>
       <Helmet>
                <meta charSet="utf-8" />
                <title>Eurovacancy Admin</title>
            </Helmet>
      <div className={styled.container}>
        <div
          style={mode ? { color: "#253858" } : { color: "#456cb0" }}
          className={styled.header_text}
        >
          <PageTitle
            title={`Salam  ${
              user ? user.name : ""
            } , Admin panelə xoş gəlmisən.`}
          />
        </div>
        <div className={styled.search_filter}>
          {/* <div
            style={
              mode
                ? {
                    backgroundColor: "",
                    border: "1px solid rgb(180, 180, 180)",
                    color: " #253858",
                  }
                : {
                    backgroundColor: "#19202D",
                    border: "1px solid #1e3664",
                    color: " #9c9c9c",
                  }
            }
            className={styled.search}
          >
            <FiSearch />
            <input
              value={examName}
              onChange={handleChange}
              placeholder="Imtahan axtar..."
              type="text"
            />
          </div> */}
        </div>
        <div className={styled.card_body}>
          {/* {exams &&
            exams
              .filter((exam) => {
                if (examName === "") {
                  return exam;
                } else if (
                  exam.name.toLowerCase().includes(examName.toLocaleLowerCase())
                ) {
                  return exam;
                }
              })
             
               
               
              } */}
                  <div
                    style={
                      mode
                        ? { backgroundColor: "#456cb0" }
                        : { backgroundColor: "#19202D" }
                    }
                    // key={exams._id}
                    className={styled.card}
                  >
                   
                    <div>
                    {/* <AiOutlineUsergroupAdd/> */}
                    <img src= {azeFlag}  style={{width:200, height:200}} />
                    </div>
                    <p>
                      {exams.length}
                    </p>
                  </div>
                  <div
                    style={
                      mode
                        ? { backgroundColor: "#456cb0" }
                        : { backgroundColor: "#19202D" }
                    }
                    // key={exams._id}
                    className={styled.card}
                  >
                   
                    <div>
                    <img src= {enFlag}  style={{width:200, height:200}} />

                    </div>
                    <p>
                      {examsen.length}
                    </p>
                  </div>
        </div>
      </div>
    </div>
  );
};

export default Quizz;
