import React from "react";
import HomeHeader from "../../components/HomeHeader";
import HomeFooter from "../../components/HomeFooter";
import styled from "./style.module.scss";
import aboutImageBg from "../.././asset/abutImage/abutImageBg.png";
import logoAbout from "../.././asset/logo/İtacLogo.png";
import vacancyImage from "../.././asset/abutImage/ourCourses.png";
import obligationİmage from '../.././asset/logo/ohdlk.png'
import { useTranslation } from "react-i18next";
const About = () => {
  const { t } = useTranslation();

  return (
    <div>
      <HomeHeader />
      <section className={styled.about_body}>
        <div className={styled.aboutTopBg}>
          <div className={styled.topTextBody}>
            <h2>
              {t("about.header.h1")}
            </h2>
            <p>
            {t("about.header.p")}

            </p>
          </div>
        </div>
        <div className={styled.container}>
          <div className={styled.abouts1}>
            <div>
              {" "}
              <img src={logoAbout} />
            </div>
            <p>
              <h1>
              {t("about.about2.h1")}
              </h1> <br />
              {t("about.about2.text")}{" "}
            </p>
          </div>
          <div className={styled.abouts2}>
             <div>
              <h1>
              {t("about.vacancy.h1")}
              </h1> <br />
             <p>
              {t("about.vacancy.text1")}{" "}
            </p>
            <p>
              {t("about.vacancy.text2")}{" "}
            </p>
            <p>
              {t("about.vacancy.text3")}{" "}
            </p>
            <p>
              {t("about.vacancy.text4")}{" "}
            </p>
            <p>
              {t("about.vacancy.text5")}{" "}
            </p>
            <p>
              {t("about.vacancy.text6")}{" "}
            </p>
            <p>
              {t("about.vacancy.text7")}{" "}
            </p>
            <p>
              {t("about.vacancy.text8")}{" "}
            </p>
            <p>
              {t("about.vacancy.text9")}{" "}
            </p>
            <p>
              {t("about.vacancy.text10")}{" "}
            </p>
            <p>
              {t("about.vacancy.text11")}{" "}
            </p>
            <p>
              {t("about.vacancy.text12")}{" "}
            </p>
            <p>
              {t("about.vacancy.text13")}{" "}
            </p>
             </div>
            <div>
              {" "}
              <img src={vacancyImage} />
            </div>
          </div>
          <div className={styled.abouts3}>
            <div>
              {" "}
              <img src={obligationİmage} />
            </div>
            <p>
              <span>
              {t("about.obligation.h1")}
              </span> <br />
              {t("about.obligation.text")}{" "}
            </p>
          </div>
        </div>
        <div className={styled.aboutTopBg2}>
          <div className={styled.topTextBody}>
            <h3>
            {t("about.endText.h1")}
            </h3>
          </div>
        </div>
      </section>
      <HomeFooter />
    </div>
  );
};

export default About;
