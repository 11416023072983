const { default: axiosInstance } = require(".");

// add exam

export const addExam = async (payload) => {
  try {
    const response = await axiosInstance.post("https://itac-server.vercel.app/api/exams/add", payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// GET ALL EXMAS
export const getAllExams = async () => {
  try {
    const response = await axiosInstance.post("https://itac-server.vercel.app/api/exams/get-all-exams");
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllQuestion = async () => {
  try {
    const response = await axiosInstance.post("https://itac-server.vercel.app/api/exams/get-all-qustion");
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// GET EXAM BY ID
export const getExamById = async (payload) => {
  try {
    const response = await axiosInstance.post(
      "https://itac-server.vercel.app/api/exams/get-exam-by-id",
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// EDIT EXAM BY ID
export const editExamById = async (payload) => {
  try {
    const response = await axiosInstance.post(
      "https://itac-server.vercel.app/api/exams/edit-exam-by-id",
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// DELETE EXAM BY ID
export const deleteExamById = async (payload) => {
  try {
    const response = await axiosInstance.post(
      "https://itac-server.vercel.app/api/exams/delete-exam-by-id",
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// ADD QUESTION TO EXAM

export const addQuestionToExam = async (payload) => {
  try {
    const response = await axiosInstance.post(
      "https://itac-server.vercel.app/api/exams/add-question-to-exam",
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// EDIT QUESTION BY ID

export const editQuestionById = async (payload) => {
  try {
    const response = await axiosInstance.post(
      "https://itac-server.vercel.app/api/exams/edit-question-in-exam",
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};


// DELETE QUESTION BY ID

export const deleteQuestionById = async (payload) => {
  try {
    const response = await axiosInstance.post('https://itac-server.vercel.app/api/exams/delete-question-in-exam', payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}


// ENGILISH VERSION


export const addExamEn = async (payload) => {
  try {
    const response = await axiosInstance.post("https://itac-server.vercel.app/api/examsen/adden", payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllQuestionEn = async () => {
  try {
    const response = await axiosInstance.post("https://itac-server.vercel.app/api/examsen/get-all-qustionen");
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllExamsEn = async () => {
  try {
    const response = await axiosInstance.post("https://itac-server.vercel.app/api/examsen/get-all-examsen");
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getExamEnById = async (payload) => {
  try {
    const response = await axiosInstance.post(
      "https://itac-server.vercel.app/api/examsen/get-examen-by-id",
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const editExamEnById = async (payload) => {
  try {
    const response = await axiosInstance.post(
      "https://itac-server.vercel.app/api/examsen/edit-examen-by-id",
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteExamEnById = async (payload) => {
  try {
    const response = await axiosInstance.post(
      "https://itac-server.vercel.app/api/examsen/delete-examen-by-id",
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};