import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import store from "./redux/store";
import { Provider } from "react-redux";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18next.use(LanguageDetector).init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem("i18nextLng"),
  resources: {
    en: {
      translation: {
        navbarItem: {
          about: "About",
          contact: "Contact",
          mainPage: "Home Page",
        },
        homeHeader: {
          h1: "Start Your Professional Career in Europe with Us",
          p: "Course & Course Search. Start the course you want with us.",
        },
        inputPlaceholder: {
          name: "Search a course...",
          location: "Country, City",
          company: "Company",
          find: "Search",
        },
        apply: {
          h1: "Apply for a course",
          text: "Don't be upset if you didn't find the course you were looking for. Contact us and we'll be the first to let you know when the course you want is shared.",
          btn: "Apply",
          btn2: "View all course",
          btn3: "More",
        },
        jobsHader: {
          h1: "Active Courses",
          p: "Now is the time to start for your future career",
        },
        selection: {
          selec1: "Sort by date",
          optionDate1: "newest",
          optionDate2: "oldest",
          selec2: "Sort by salary",
          optionSalary1: "most",
          optionSalary2: "least",
        },
        joinUs: {
          h1: "Join us on Telegram",
          text: "If you want to be notified of active courses, join us on Telegram and be the first to know about the latest courses.",
          btn: "Join us on Telegram",
        },
        carusel: {
          h1: "Our Courses",
          p: "Job Opportunities Abroad After Completing Your Course",
        },
        about: {
          header: {
            h1: "Hello, Welcome to İtac!",
            p: "Get a chance to work in the country and other foreign countries, especially the countries that are part of the European Union.",
          },
          about2: {
            h1: "About Us",
            text: "Since 2019, ITAC is a unique company in Baku that conducts personnel training in several fields of industry and offers job opportunities in Europe to its graduates in a number of European countries. The ITAC certification program, recognized by a number of large industrial enterprises in Germany and Poland, announces the admission of students in Baku. Students who complete the specialized courses organized by ITAC and receive certification after passing internal exams get a chance to be employed in the world-renowned institutions of Azerbaijan and the Republic of Poland.",
          },
          vacancy: {
            h1: "Our Courses",
             text1: "-Foreign language courses (engilish, german language)",
             text2: "-Accounting courses",
             text3: "-WEB developer and IT courses",
             text4: "-Social Media Marketing courses (SMM)",
             text5: "-Interior Design courses",
             text6: "-Painting courses",
             text7: "-Tailoring courses",
             text8: "-Cooking courses",
             text9: "-Plumbing courses",
             text10: "-Security organization and camera control courses",
             text11: "-Welding Courses",
             text12: "-Electrical courses",
             text13: "-Locksmith courses",
          },
          obligation: {
            h1: "Obligations",
            text: " Employees of the mentioned courses are provided with free reception, accommodation, accommodation, work uniforms, medical services and banking services in the Republic of Poland. If accepted, the document fees and travel expenses during the documentation process are paid by the candidate. Candidates applying for employment are required to have work experience and foreign language skills (Russian or English) for the vacancy they have chosen.",
          },
          endText: {
            h1: "Students who successfully complete the course will have the chance to work in the country and other foreign countries, mainly in the countries that are part of the European Union.",
          },
        },
        contact: {
          h1: "Do you have questions? Share with us",
          input: {
            inp1: {
              header: "Name, Surname",
              placeholder: "Enter first name, last name",
            },
            inp2: {
              header: "E-mail",
              placeholder: "Enter your email account",
            },
            inp3: {
              header: "Mobile number",
              placeholder: "xx xxx xx xx",
            },
            inp4: {
              header: "Message",
              placeholder: "Enter your message...",
            },
            btn: "Send",
            validation: {
              empty: "*Fill this box!",
            },
          },
        },
        alert: {
          success: "Your message has been successfully sent!",
          faild: "Something went wrong!",
        },
        alert2: {
          success: "Your request has been successfully sent!",
          faild: "Something went wrong!",
        },
        footer: {
          copyright: "Copyright © 2023 İTAC. All rights reserved.",
          pages: {
            h1: "Pages",
            about: "About Us",
            contact: "Contact",
            mainPage: "Home Page",
            watchUs: "Watch Us",
          },
          contact: {
            h1: "Contact Information",
            location1: "Head office: Lermontov Street 109",
            location2: "Azadlig branch address: Baku city, Azadlig avenue 88",
            location3:
              "Education and production plant address: Alasgarov Gayibov 1222",
          },
        },
        detail: {
          h1: "About Course",
          buttons: {
            apply: "Apply",
            creatCv: "Create CV",
            uploadCv: "Download Ready Cv",
            close: "Close",
          },
          createCvInp: {
            title: "Create your own CV",
            jobName: "Job Name",
            companyName: "Company",
            Location: "Country where you want to continue your career",
            inp1: {
              label: "Name, Surname",
              placeholder: "enter first name, last name",
            },
            inp2: {
              label: "Age",
              placeholder: "Enter age",
            },
            inp3: {
              label: "E-mail",
              placeholder: "Enter Email",
            },
            inp4: {
              label: "Mobile number",
              placeholder: "xx xxx xx xx",
            },
            inp5: {
              label: "Language knowledge",
              placeholder: "Enter language skills in detail",
            },
            inp6: {
              label: "Your residential address",
              placeholder: "Enter your residential address",
            },
            inp7: {
              label: "Education",
              placeholder: "Enter your education level in detail",
            },
            inp8: {
              label: "Your work experience",
              placeholder: "Enter your work experience in detail",
            },
            inp9: {
              etiket: "Əvvəllər getdiyiniz ölkələr",
              placeholder: "Daha əvvəl getdiyiniz ölkələri daxil edin",
            },
            inp10: {
              etiket: "Bizim haqqımızda haradan öyrəndiniz?",
              placeholder: "Bizim haqqımızda haradan öyrənmisiniz",
            },
            btn: "Send",
            btnClose: "Close",
          },
        },
        applyCv: {
          buttons: {
            apply: "Apply",
            creatCv: "Create CV",
            uploadCv: "Upload Cv",
            close: "Close",
          },
          createCvInp: {
            title: "Create your own CV",
            jobName: "The course you are applying for",
            jobNamePlace: "Enter the name of the course.",
            Location: "Country where you want to continue your career",
            LocationPlace: "Enter the name of the country.",
            inp1: {
              label: "Name, Surname",
              placeholder: "enter first name, last name",
            },
            inp2: {
              label: "Age",
              placeholder: "Enter age",
            },
            inp3: {
              label: "E-mail",
              placeholder: "Enter Email",
            },
            inp4: {
              label: "Mobile number",
              placeholder: "xx xxx xx xx",
            },
            inp5: {
              label: "Language knowledge",
              placeholder: "Enter language skills in detail",
            },
            inp6: {
              label: "Your residential address",
              placeholder: "Enter your residential address",
            },
            inp7: {
              label: "Education",
              placeholder: "Enter your education level in detail",
            },
            inp8: {
              label: "Your work experience",
              placeholder: "Enter your work experience in detail",
            },
            inp9: {
              label: "Countries you've been to before",
              placeholder: "Enter countries you've been to before",
            },
            inp10: {
              label: "Where did you learn about us?",
              placeholder: "Enter where did you learn about us",
            },
            btn: "Send",
            btnClose: "Close",
          },
        },
        hourSalary: "Hourly wages:",
        lookAtAll: "Look at all courses",
        noResult:"The result you are looking for was not found"

      },
    },
    az: {
      translation: {
        navbarItem: {
          about: "Haqqımızda",
          contact: "Əlaqə",
          mainPage: "Ana səhifə",
        },
        homeHeader: {
          h1: "Avropada Peşəkar Karyerana Bizimlə Başla",
          p: "Kurslar & Kurs axtar. İstədiyin kursa bizimlə başla.",
        },
        inputPlaceholder: {
          name: "Kurs axtar...",
          location: "Ölkə, Şəhər",
          company: "Şirkət",
          find: "Axtar",
        },
        apply: {
          h1: "Kursa Müraciət et",
          text: "Axtardığınız kursu tapmadınızsa üzülməyin. Bizə müaraciət edin və istədiyiniz kurs paylaşıldığda ilk olaraq sizə xəbər verəcəyik.",
          btn: "Müraciət et",
          btn2: "Bütün kurslara bax",
          btn3: "Daha çox",
        },
        jobsHader: {
          h1: "Aktiv Olan kurslar",
          p: "İndi gələcək karyeranıza başlamağın vaxtıdır",
        },
        selection: {
          selec1: "Tarixə görə sırala",
          optionDate1: "ən yeni",
          optionDate2: "ən köhnə",
          selec2: "Maaşa görə sırala",
          optionSalary1: "ən çox",
          optionSalary2: "ən az",
        },
        joinUs: {
          h1: "Telegramda bizə qoşul",
          text: "Aktiv olan kursların sizə bildiriş kimi gəlməyini istəyirsinizsə telegramda bizə qoşulun, ən yeni kurslardan ilk siz xəbərdar olun.",
          btn: "Telegramda bizə qoşul",
        },
        carusel: {
          h1: "Kurslarımız",
          p: "Kursu müvəffəqiyyətlə Bitirdikdən Sonra Xaricdə İş İmkanları",
        },
        about: {
          header: {
            h1: "Salam, İtac'a Xoş Gəlmisiniz!.",
            p: "Avropa birliyinə daxil olan ölkələr başda olmaqla, ölkə daxilində və digər xarici ölkələrdə işləmək şansı əldə et.",
          },
          about2: {
            h1: "Haqqımızda",
            text: "Bakıda Avropanın bir sıra ölkələrində sənayenin bir neçə sahələri üzrə kadr hazırlığını aparan və məzunlarına Avropada iş imkanları təklif edən unikal bir şirkət - İTAC 2019-cu ildən fəaliyyət göstərir. Almaniya və Polşanın bir sıra böyük sənaye müəssisələri tərəfindən tanınan İTAC sertifikasiya proqramı Bakıda tələbə qəbulunu elan edir. İTAC tərəfindən təşkil olunan ixtisas kurslarını başa vuran və daxili imtahanlardan keçərək sertifikasiya alan tələbələr yiyələndikləri ixtisaslar üzrə Azərbaycanın və Polşa Respublikasının dünya üzrə tanınmış müəssisələrində işə qəbul olma şansı əldə edirlər.",
          },
          vacancy: {
            h1: "Kurslarımız",
            text1: "-Xarici dil kursları (ingilis, alman dili) ",
            text2: "-Mühasibatlıq kursları",
            text3: "-WEB developer və İT kursları",
            text4: "-Sosial Media Marketing kursları (SMM)",
            text5: "-İnteryer Dizayn kursları",
            text6: "-Rəngsaz kursları",
            text7: "-Dərzilik kursları",
            text8: "-Aşpazlıq kursları",
            text9: "-Santexnik kursları",
            text10: "-Mühafizənin təşkili və kamera nəzarət kursları",
            text11: "-Qaynaq kursları",
            text12: "-Elektrik kursları",
            text13: "-Çilingər kursları",
            
          },
          obligation: {
            h1: "Öhdəliklər",
            text: "Qeyd edilən kurslar üzrə işçilər Polşa Respublikasında ödənişsiz qarşılanma, yerləşdirilmə, yaşayış yeri, işçi formaları, tibbi xidmətlər və bank xidmətləri ilə təmin olunur. İşə qəbul olunduğu təqdirdə sənədləşmə prossesi zamanı sənədlərin rüsumları və yol xərcləri namizəd tərəfindən ödənilir. İşə qəbul üçün müraciət edən namizədlərdən seçdiyi vakansiya üzrə iş təcrübəsinin və xarici dil biliklərinin(Rus və yaxud İngilis dili) olması tələb olunur.",
          },
          endText: {
            h1: "Kursu müvəffəqiyətlə bitirən tələbələr Avropa birliyinə daxil olan ölkələr başda olmaqla, ölkə daxilində və digər xarici ölkələrdə işləmək şansı əldə edəcəklər.",
          },
        },
        contact: {
          h1: "Suallarınız var? Bizimlə Bölüş",
          input: {
            inp1: {
              header: "Ad Soyad",
              placeholder: "ad,soyad daxil edin",
            },
            inp2: {
              header: "E-mail",
              placeholder: "E-mail hesabınızı daxil edin",
            },
            inp3: {
              header: "Mobil nömrə",
              placeholder: "xx xxx xx xx",
            },
            inp4: {
              header: "Mesaj",
              placeholder: "Mesajınızı daxil edin...",
            },
            btn: "Göndər",
            validation: {
              empty: "*Bu xananı doldurun!",
            },
          },
        },
        alert: {
          success: "Mesajınız uğurla göndərildi!",
          faild: "Xəta baş verdi",
        },
        alert2: {
          success: "Müraciətiniz uğurla göndərildi!",
          faild: "Xəta baş verdi",
        },
        footer: {
          copyright: "Copyright © 2023 İTAC. Bütün hüquqlar qorunur.",
          pages: {
            h1: "Səhifələr",
            about: "Haqqımızda",
            contact: "Əlaqə",
            mainPage: "Ana səhifə",
            watchUs: "Bizi izlə",
          },
          contact: {
            h1: "Əlaqə məlumatı",
            location1: "Baş ofis:Lermontov küçəsi 109",
            location2:
              "Azadlıq filialı ünvanı:Bakı Şəhəri, Azadlıq prospekti 88",
            location3:
              "Tədris və istehsal zavodu ünvanı:Ələsgərov Qayıbov 1222",
          },
        },
        detail: {
          h1: "Kurs Haqqında",
          buttons: {
            apply: "Müraciət et",
            creatCv: "CV Yaz",
            uploadCv: "Hazır Cv Yüklə",
            close: "Bağla",
          },
          createCvInp: {
            title: "Öz CV'nizi yaradın",
            jobName: "Müraciət etdiyiniz kurs",
            companyName: "Müraciət etdiyiniz şirkət",
            Location: "Karyeraznızı davam elətdirmək istədiyiniz ölkə",
            inp1: {
              label: "Ad, Soyad",
              placeholder: "ad, soyad daxil edin",
            },
            inp2: {
              label: "Yaş",
              placeholder: "Yaşı daxil",
            },
            inp3: {
              label: "E-mail",
              placeholder: "E-mail daxil edin",
            },
            inp4: {
              label: "Mobil nömrə",
              placeholder: "xx xxx xx xx",
            },
            inp5: {
              label: "Dil biliyi",
              placeholder: "Dil biliyini ətraflı şəkildə daxil edin",
            },
            inp6: {
              label: "Yaşayış ünvanınız",
              placeholder: "Yaşayış ünvanınızı daxil edin",
            },
            inp7: {
              label: "Təhsil",
              placeholder: "Təhsil səviyyənizi ətraflı şəkild daxil edin",
            },
            inp8: {
              label: "İş təcrübəniz",
              placeholder: "İş təcrübənizi ətraflı şəkild daxil edin",
            },
            btn: "Göndər",
            btnClose: "Bağla",
          },
        },
        applyCv: {
          buttons: {
            apply: "Müraciət et",
            creatCv: "CV Yarat",
            uploadCv: "Cv Yüklə",
            close: "Bağla",
          },
          createCvInp: {
            title: "Öz CV'nizi yaradın",
            jobName: "Müraciət etdiyiniz kurs",
            jobNamePlace: "Kursun adını daxil edin.",
            Location: "Karyeraznızı davam elətdirmək istədiyiniz ölkə",
            LocationPlace: "Ölkənin adını daxil edin.",
            inp1: {
              label: "Ad, Soyad",
              placeholder: "ad, soyad daxil edin",
            },
            inp2: {
              label: "Yaş",
              placeholder: "Yaşı daxil",
            },
            inp3: {
              label: "E-mail",
              placeholder: "E-mail daxil edin",
            },
            inp4: {
              label: "Mobil nömrə",
              placeholder: "xx xxx xx xx",
            },
            inp5: {
              label: "Dil biliyi",
              placeholder: "Dil biliyini ətraflı şəkildə daxil edin",
            },
            inp6: {
              label: "Yaşayış ünvanınız",
              placeholder: "Yaşayış ünvanınızı daxil edin",
            },
            inp7: {
              label: "Təhsil",
              placeholder: "Təhsil səviyyənizi ətraflı şəkild daxil edin",
            },
            inp8: {
              label: "İş təcrübəniz",
              placeholder: "İş təcrübənizi ətraflı şəkild daxil edin",
            },
            inp9: {
              label: "Əvvəllər getdiyiniz ölkələr",
              placeholder: "Əvvəllər getdiyiniz ölkələri daxil edin",
            },
            inp10: {
              label: "Bizi haradan öyrəndiniz?",
              placeholder: "Bizi haradan öyrəndiyinizi daxil edin",
            },
            btn: "Göndər",
            btnClose: "Bağla",
          },
        },
        hourSalary: "Saatlıq Ə/H:",
        lookAtAll: "Bütün kurslara bax",
        noResult:"Axtardığınız nəticə tapılmadı"

      },
    },
    fallbackLng: "az",
    debug: true,
  },
});

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);
