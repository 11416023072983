import React, { useState } from "react";
import { Link } from "react-scroll";
import style from "./style.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { IconContext } from "react-icons";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { AiTwotoneHome } from "react-icons/ai";
import { FcAbout } from "react-icons/fc";
import { FcBusinessContact } from "react-icons/fc";
import logo from "../../asset/logo/İtacTransparent.png";
import logoWhite from "../../asset/logo/itacTransparentWhite.png";
import { useTranslation } from "react-i18next";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import azeFg from "../.././asset/f/aze.png";
import engFg from "../.././asset/f/uk.png";

const HomeHeader = () => {
  const [scroll, setScroll] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const navigate = useNavigate();

  const showSidebar = () => setSidebar(!sidebar);
  const location = useLocation()

  window.onscroll = function () {
    let scrool = window.pageYOffset;
    if (1 < scrool) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    // Dil tercihini değiştir
    i18n.changeLanguage(lng);
    window.location.reload();

    // Dil tercihini LocalStorage'a kaydet
    localStorage.setItem("i18nextLng", lng);
  };
  const localStore = localStorage.getItem("i18nextLng");
  var Lang = "";
  if (localStore === "az") {
    Lang = "Aze";
  } else if (localStore === "en") {
    Lang = "En";
  }
  const [age, setAge] = React.useState(Lang);
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <>
      <div
        style={
          location.pathname==='/'?
          (scroll
            ? { height: "70px", backgroundColor: "white" }
            : { height: "80px", backgroundColor: "transparent" }):({height: "70px", backgroundColor: "white"})
        }
        className={style.navbar}
      >
        <div className={style.container}>
          <div className={style.navbar_left}>
            <Link onClick={() => navigate("/")}>
            {  
          location.pathname==='/'?
              (scroll?  <img src={logo} alt="logo" />:<img src={logoWhite} alt="logo"/>  ):(<img src={logo} alt="logo" />)     
            }
              {/* <h1>
                <span    style={
          scroll
            ? { color: "black"  }
            : { color: "white" }
        }>ITAC</span>
                
              </h1> */}
            </Link>
          </div>
          <div
            style={
          location.pathname==='/'?
          (scroll ? { color: "black" } : { color: "white" }):({ color: "black" })
 }
            className={style.navbar_middle}
          >
            {/* <button>Az</button>
            <button >En</button> */}
            <Link
              onClick={() => navigate("/")}
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              style={
          location.pathname==='/'?
          (scroll ? { color: "black" } : { color: "white" }):({ color: "black" })
 }
            >
              {t("navbarItem.mainPage")}
            </Link>
            <Link
              onClick={() => navigate("/about")}
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              style={
          location.pathname==='/'?
          (scroll ? { color: "black" } : { color: "white" }):({ color: "black" })
 }
            >
              {t("navbarItem.about")}
            </Link>
            <Link
              onClick={() => navigate("/contact")}
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              style={
          location.pathname==='/'?
          (scroll ? { color: "black" } : { color: "white" }):({ color: "black" })
 }
            >
              {t("navbarItem.contact")}
            </Link>

            <div
              sx={{
                minWidth: 40,
                minHeight: 30,
                display: "flex",
                alignItems: "center",
                border: "none",
              }}
            >
              <FormControl>
                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                <Select
                  value={age}
                  onChange={handleChange}
                  label={null}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "7px",
                    height: "40px",
                    width: "70px",
                    
                  }}
                >
                  <MenuItem
                    onClick={() => changeLanguage("az")}
                    value={"Aze"}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "7px",
                      height: 50,
                    }}
                  >
                    <img src={azeFg} style={{ width: 23, height: 23 }} />{" "}
                  </MenuItem>
                  <MenuItem
                    onClick={() => changeLanguage("en")}
                    value={"En"}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "7px",
                      height: 50,
                    }}
                  >
                    {" "}
                    <img src={engFg} style={{ width: 23, height: 23 }} />{" "}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </div>

      {/* HAMBURGER MENU */}
      <div
        style={
          location.pathname==='/'?
          (scroll
            ? { height: "70px", backgroundColor: "white" }
            : { height: "80px", backgroundColor: "transparent" }):({height: "70px", backgroundColor: "white"})
        }
        className={style.ham_menu}
      >
        <div className={style.switch_contanier}>
          <div className={style.switch_body}></div>
        </div>
        <IconContext.Provider value={{ color: "#264e93" }}>
          <div className={style.hamburger_menu}>
            <div
              onClick={() => navigate("/")}
              className={style.ham_menu_navbar_left}
            >
               {  
          location.pathname==='/'?
              (scroll?  <img src={logo} alt="logo" />:<img src={logoWhite} alt="logo"/>  ):(<img src={logo} alt="logo" />)     
            }
              {/* <h1>
                <span style={{ color: "#202124" }}>ITAC</span>
              </h1> */}
            </div>

            <Link to="#" className={style.menu_bars}>
              <Box
                sx={{
                  minWidth: "60px",
                  minHeight: 30,
                  display: "flex",
                  alignItems: "center",
                  gap: "7px",
                  border: "none",
                  zIndex: 999,
                }}
              >
                <FormControl>
                  {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                  <Select
                    value={age}
                    onChange={handleChange}
                    label={null}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "7px",
                      height: "32px",
                    }}
                  >
                    <MenuItem
                      onClick={() => changeLanguage("az")}
                      value={"Aze"}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "7px",
                        height: 50,
                      }}
                    >
                      <img src={azeFg} style={{ width: 23, height: 23 }} />{" "}
                    </MenuItem>
                    <MenuItem
                      onClick={() => changeLanguage("en")}
                      value={"En"}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "7px",
                        height: 50,
                      }}
                    >
                      {" "}
                      <img src={engFg} style={{ width: 23, height: 23 }} />{" "}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <FaIcons.FaBars onClick={showSidebar} />
            </Link>
          </div>
          <nav className={sidebar ? style.active : style.nav_menu}>
            <ul className={style.nav_menu_items} onClick={showSidebar}>
              <li className={style.navbar_toggle}>
                <Link to="#" className={style.menu_bars}>
                  <AiIcons.AiOutlineClose onClick={showSidebar} />
                </Link>
              </li>
              <div className={style.nav_text}>
                <Link
                  onClick={() => navigate("/")}
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  <AiTwotoneHome /> {t("navbarItem.mainPage")}
                </Link>
                <Link
                  onClick={() => navigate("/about")}
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  <FcAbout />
                  {t("navbarItem.about")}
                </Link>
                <Link
                  onClick={() => navigate("/contact")}
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  <FcBusinessContact /> {t("navbarItem.contact")}
                </Link>
              </div>
            </ul>
          </nav>
        </IconContext.Provider>
      </div>
    </>
  );
};

export default HomeHeader;
