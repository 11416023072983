import React, { useState } from "react";
import ReactSimplyCarousel from "react-simply-carousel";
import styled from "./style.module.scss";
import imageSey from "../../.././src/asset/carusel/1.jpg";
import imageEng from "../../.././src/asset/carusel/2.jpg";
import imageWel from "../../.././src/asset/carusel/5.jpg";
import imageDev from "../../.././src/asset/carusel/3.jpg";
import imageN from "../../.././src/asset/carusel/4.jpg";
import imageD from "../../.././src/asset/carusel/7.jpg";
import { useTranslation } from "react-i18next";


const Carusel = () => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const { t } = useTranslation();
  
  return (
    <div className={styled.carusel_body}>
    <h2>
    {t("carusel.h1")}
    </h2>
      <p>
      {t("carusel.p")}
      </p>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        forwardBtnProps={{
          style: {
            alignSelf: "center",
            background: "#9dc3da",
            border: "none",
            borderRadius: "50%",
            color: "white",
            cursor: "pointer",
            fontSize: "20px",
            height: 40,
            lineHeight: 1,
            textAlign: "center",
            width: 40,
            top:"58%",
            position:'absolute',
            right:0,
            zIndex:10,
            marginRight:"0"
          },
          children: <span>{`>`}</span>,
        }}
        backwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: "center",
            background: "#9dc3da",
            border: "none",
            borderRadius: "50%",
            color: "white",
            cursor: "pointer",
            fontSize: "20px",
            height: 40,
            lineHeight: 1,
            textAlign: "center",
            width: 40,
            position:'absolute',
            top:"58%",
            left:0,
            zIndex:10,
            marginLeft:"0"
           
          },
          children: <span>{`<`}</span>,
        }}
        responsiveProps={[
          {
            itemsToShow: 1,
            itemsToScroll: 1,
            minWidth: 768,
          },
        ]}
        speed={600}
        easing="linear"
      >
        {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}
            
        <div className={styled.carusel_item}>
          <div className={styled.carusel_contanier}>
            <div className={styled.image_body}>
              <img src={imageSey} alt="" />
              {/* <h4>Katowice</h4> */}
              
            </div>
            <div className={styled.image_body}>
              <img src={imageEng} alt="" />
              {/* <h4>Krakow</h4> */}
            </div>
            <div className={styled.image_body}>
              <img src={imageN} alt="" />
              {/* <h4>Varşava</h4> */}
      
            </div>
          </div>
        </div>
        <div className={styled.carusel_item}>
          <div className={styled.carusel_contanier}>
            
            <div className={styled.image_body}>
              <img src={imageWel} alt="" />
              {/* <h4>Hamburg</h4> */}
          
            </div>
            <div className={styled.image_body}>
              <img src={imageD} alt="" />
              {/* <h4>Munich</h4> */}
            </div>
            <div className={styled.image_body}>
              <img src={imageDev} alt="" />
              {/* <h4>Budapest</h4> */}
            </div>
          </div>
        </div>
        {/* <div className={styled.carusel_item}>
          <div className={styled.carusel_contanier}>
            
            <div className={styled.image_body}>
              <img src={newYork} alt="" />
              <h4>New York</h4>
              <p>12 Job</p>
            </div>
            <div className={styled.image_body}>
              <img src={paris} alt="" />
              <h4>Paris</h4>
              <p>15 Job</p>
            </div>
            <div className={styled.image_body}>
              <img src={amsterdam} alt="" />
              <h4>Amstredam</h4>
              <p>8 Job</p>
            </div>
          </div>
        </div> */}
   

      </ReactSimplyCarousel>
    </div>
  );
};

export default Carusel;
