import { message } from "antd";
import React, { useEffect, useState } from "react";
import PageTitle from "../../../../components/PageTitle/index";
import styled from "./style.module.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MdOutlineDriveFileRenameOutline } from "react-icons/md";
import { BiCategoryAlt } from "react-icons/bi";
import { GiDuration } from "react-icons/gi";
import { SiVirustotal } from "react-icons/si";
import { VscPass } from "react-icons/vsc";
import {
  addExam,
  addExamEn,
  deleteQuestionById,
  editExamById,
  editExamEnById,
  getExamById,
  getExamEnById,
} from "../../../../apicalls/exmas";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  HideLoading,
  ShowLoading,
} from "../../../../redux/loaderSlice/loaderSlice";
import { Tabs } from "antd";
import TabPane from "rc-tabs/lib/TabPanelList/TabPane";
import AddEditQuestion from "../AddEditQuestion";
import { FiEdit3 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BsLink45Deg } from "react-icons/bs"; //link
import { BsInfoCircle } from "react-icons/bs"; //info
import { RiMoneyEuroCircleLine } from "react-icons/ri"; //pul
import { MdOutlineWrongLocation } from "react-icons/md"; //location
import { Table } from "antd";

const AddEditExams = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.darkMode);
  const [examData, setExamData] = useState([]);
  const [showAddEditQuestionModal, setShowAddEditQuestionModal] =
    useState(false);
  const [selcetedQuestion, setSelcetedQuestion] = useState(null);
  const params = useParams();
 const [selectVal,setSelectVal] = useState('');
 const [selectValue, setSelectValue] = useState('');
  const handleChange = (event)=>{
    const selectedValue = event.target.value
    setSelectVal(selectedValue)
    setSelectValue(selectedValue)
    sessionStorage.setItem("Language", selectedValue);
  }
  useEffect(() => {
      const selecVal = localStorage.getItem("langValue")
      setSelectVal(selecVal)
      dispatch(ShowLoading())
      getExamData()
      dispatch(HideLoading())
    
  }, [selectVal]);

  const formik = useFormik({
    initialValues: {
      name: "",
      category: "",
      compName: "",
      about: "",
      part2: "",
      part3: "",
      part4: "",
      part5: "",
      part6: "",
      part7: "",
      part8: "",
      part9: "",
      part10: "",
      part11: "",
      part12: "",
      special:"",
      salary: "",
      location: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(70, "İş adı 35 simvoldan çox ola bilməz")
        .required("*Bu xananı doldurun!"),
      category: Yup.string()
        .max(350, "link 350 simvoldan çox ola bilməz")
        .required("*Bu xananı doldurun!"),
      compName: Yup.string()
        .max(35, "Şirkət adı 35 simvoldan çox ola bilməz")
        .required("*Bu xananı doldurun!"),
      about: Yup.string()
        .min(50, "Haqqında 50 simvoldan az ola bilməz")
        .required("*Bu xananı doldurun!"),
      salary: Yup.string()
        .min(1, "Maaş 1 simvoldan az ola bilməz")
        .required("*Bu xananı doldurun!"),
      location: Yup.string()
        .min(3, "link 3 simvoldan az ola bilməz")
        .required("*Bu xananı doldurun!"),
    }),
    onSubmit: async (values) => {
      // console.log("salam meeki");
      try {
        dispatch(ShowLoading());
        let response;

        if (params.id && selectVal === 'az') {
          response = await editExamById({
            ...values,
            examId: params.id,
          });
        }
         else if (params.id && selectVal === 'en') {
          response = await editExamEnById({
            ...values,
               examId: params.id,
          });
         }
        else {
          if(selectValue && selectValue === 'az'){
            response = await addExam(values);
          }
          else if (selectValue && selectValue === 'en'){
            response = await addExamEn(values);
          }
        }

        if (response && response.success) {
          message.success(response && response.message);
          navigate("/admin/exams");
        } else {
          message.error(response && response.message);
        }
        dispatch(HideLoading());
        sessionStorage.removeItem("Language");
      } catch (error) {
        dispatch(HideLoading());
        message.error(error && error.message);
      }
    },
  });

  console.log('selecVal',selectVal);
  const getExamData = async () => {
    try {
      dispatch(ShowLoading());
      var response; 
      if(selectVal && selectVal === 'en'){
        response = await getExamEnById({
          examId: params.id,
        });
        dispatch(HideLoading());

      }
      else if (selectVal && selectVal === 'az'){
        response = await getExamById({
          examId: params.id,
        });
        dispatch(HideLoading());
      }
      if (response && response.success) {
        setExamData(response.data);
      } else {
        message.error(response && response.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error && error.message);
    }
  };
  useEffect(() => {
    if (params.id) {
      getExamData();
      
    }
  }, []);

  useEffect(() => {
    if (examData) {
      formik.setValues({
        name: examData.name,
        category: examData.category,
        compName: examData.compName,
        about: examData.about,
        part2: examData.part2,
        part3: examData.part3,
        part4: examData.part4,
        part5: examData.part5,
        part6: examData.part6,
        part7: examData.part7,
        part8: examData.part8,
        part9: examData.part9,
        part10: examData.part10,
        part11: examData.part11,
        part12: examData.part12,
        special: examData.special,
        salary: examData.salary,
        location: examData.location,
      });
    }
  }, [examData]);

  const deleteQuestion = async (questionId) => {
    try {
      dispatch(ShowLoading());
      const response = await deleteQuestionById({
        questionId,
        examId: params.id,
      });
      dispatch(HideLoading());
      if (response && response.success) {
        message.success(response && response.message);
        getExamData();
      } else {
        message.error(response && response.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error && error.message);
    }
  };

  const questionColumns = [
    {
      title: "iş",
      dataIndex: "name",
      align: "left",
      width: "150px",
    },
    {
      title: "İş haqqında",
      dataIndex: "correctOption",
      // align: "left",
      width: "800px",
    },
    {
      title: "Maaş",
      dataIndex: "salary",
      align: "left",
      width: "100px",
    },
    {
      title: "Ünvan",
      dataIndex: "loaction",
      align: "left",
      width: "100px",
    },

    {
      title: "Əməliyyatlar",
      dataIndex: "action",
      align: "right",
      render: (text, record) => {
        return (
          <div className={styled.action}>
            <span
              onClick={() => {
                setSelcetedQuestion(record);
                setShowAddEditQuestionModal(true);
              }}
            >
              <FiEdit3 />
            </span>
            <span
              onClick={() => {
                deleteQuestion(record._id);
              }}
            >
              <RiDeleteBin6Line />
            </span>
          </div>
        );
      },
    },
  ];
  return (
    <div
      style={
        mode
          ? { backgroundColor: "rgb(244, 244, 244)" }
          : { backgroundColor: "#121721" }
      }
      className={styled.edit_body}
    >
      <div className={styled.addExam_body}>
        <div className={styled.addExam_text}>
          <PageTitle
            title={params.id ? "Kursa düzəliş et" : "Kurs əlavə et"}
          />
        </div>
        <div
          style={
            mode ? { backgroundColor: "white" } : { backgroundColor: "#131e32" }
          }
          className={styled.form_body}
        >
          {(examData || !params.id) && (
            <form onSubmit={formik.handleSubmit}>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Kurs Təfərrüatı" key="1">
                  <div className={styled.tab_pane1}>
                  <select
                  // value={selectValue}
                  onChange={handleChange}
                  style={{width:'100px',height:'30px',fontSize:'20px',backgroundColor:'#4477CE',color:'white',borderRadius:'8px'}}
                  >
                    <option value={'az'} onClick={()=> handleChange('az')}>Az</option>
                    <option value={'en'} onClick={()=> handleChange('en')}>En</option>
                  </select>
                    <div
                      style={mode ? { color: "#253858" } : { color: "white" }}
                      className={styled.inp_name_body}
                    >
                      <p
                        style={
                          mode ? { color: "#253858" } : { color: "#264e93" }
                        }
                        className={styled.exam_name}
                      >
                        Kurs adı
                      </p>
                      <div className={styled.inp_body}>
                        <MdOutlineDriveFileRenameOutline />
                        <input
                          style={
                            mode ? { color: "#253858" } : { color: "white" }
                          }
                          id="name"
                          name="name"
                          type="text"
                          placeholder="Kurs adını daxil edin."
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                      </div>
                      {formik.touched.name && formik.errors.name ? (
                        <div className={styled.require}>
                          {formik.errors.name}
                        </div>
                      ) : null}
                    </div>

                    <div>
                      <div className={styled.inp_name_body}>
                        <p
                          style={
                            mode ? { color: "#253858" } : { color: "#264e93" }
                          }
                          className={styled.exam_name}
                        >
                          Kursun Logosu
                        </p>
                        <div
                          style={
                            mode ? { color: "#253858" } : { color: "white" }
                          }
                          className={styled.inp_body}
                        >
                          <BsLink45Deg />
                          <input
                            style={
                              mode ? { color: "#253858" } : { color: "white" }
                            }
                            id="category"
                            name="category"
                            type="text"
                            placeholder="Kurs logosunu link şəklində əlavə edin.Linkin doğruluğunu browserde yoxlayin!"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.category}
                          />
                        </div>
                        {formik.touched.category && formik.errors.category ? (
                          <div className={styled.require}>
                            {formik.errors.category}
                          </div>
                        ) : null}
                      </div>
                      <div className={styled.inp_name_body}>
                        <p
                          style={
                            mode ? { color: "#253858" } : { color: "#264e93" }
                          }
                          className={styled.exam_name}
                        >
                          Kurs adı
                        </p>
                        <div
                          style={
                            mode ? { color: "#253858" } : { color: "white" }
                          }
                          className={styled.inp_body}
                        >
                          <MdOutlineDriveFileRenameOutline />
                          <input
                            style={
                              mode ? { color: "#253858" } : { color: "white" }
                            }
                            id="compName"
                            name="compName"
                            type="text"
                            placeholder="Kurs adını daxil edin"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.compName}
                          />
                        </div>
                        {formik.touched.compName && formik.errors.compName ? (
                          <div className={styled.require}>
                            {formik.errors.compName}
                          </div>
                        ) : null}
                      </div>
                      <div className={styled.inp_name_body_about}>
                        <p
                          style={
                            mode ? { color: "#253858" } : { color: "#264e93" }
                          }
                          className={styled.exam_name}
                        >
                          Kurs haqqında
                        </p>
                        <div
                          style={
                            mode ? { color: "#253858" } : { color: "white" }
                          }
                          className={styled.inp_body}
                        >
                          <BsInfoCircle />
                          <textarea
                            style={
                              mode ? { color: "#253858" } : { color: "white" }
                            }
                            id="about"
                            name="about"
                            type="text"
                            placeholder="Ətraflı məlumat yazın"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.about}
                          />
                        </div>
                        {formik.touched.about && formik.errors.about ? (
                          <div className={styled.require}>
                            {formik.errors.about}
                          </div>
                        ) : null}
                      </div>

                      <div className={styled.inp_name_body_about}>
                        <p
                          style={
                            mode ? { color: "#253858" } : { color: "#264e93" }
                          }
                          className={styled.exam_name}
                        >
                          Əlavə
                        </p>
                        <div
                          style={
                            mode ? { color: "#253858" } : { color: "white" }
                          }
                          className={styled.inp_body}
                        >
                          <BsInfoCircle />
                          <textarea
                            style={
                              mode ? { color: "#253858" } : { color: "white" }
                            }
                            id="part2"
                            name="part2"
                            type="text"
                            placeholder="Ətraflı məlumat yazın"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.part2}
                          />
                        </div>
                      </div>
                      <div className={styled.inp_name_body_about}>
                        <p
                          style={
                            mode ? { color: "#253858" } : { color: "#264e93" }
                          }
                          className={styled.exam_name}
                        >
                          Əlavə
                        </p>
                        <div
                          style={
                            mode ? { color: "#253858" } : { color: "white" }
                          }
                          className={styled.inp_body}
                        >
                          <BsInfoCircle />
                          <textarea
                            style={
                              mode ? { color: "#253858" } : { color: "white" }
                            }
                            id="part3"
                            name="part3"
                            type="text"
                            placeholder="Ətraflı məlumat yazın"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.part3}
                          />
                        </div>
                      </div>
                      <div className={styled.inp_name_body_about}>
                        <p
                          style={
                            mode ? { color: "#253858" } : { color: "#264e93" }
                          }
                          className={styled.exam_name}
                        >
                          Əlavə
                        </p>
                        <div
                          style={
                            mode ? { color: "#253858" } : { color: "white" }
                          }
                          className={styled.inp_body}
                        >
                          <BsInfoCircle />
                          <textarea
                            style={
                              mode ? { color: "#253858" } : { color: "white" }
                            }
                            id="part4"
                            name="part4"
                            type="text"
                            placeholder="Ətraflı məlumat yazın"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.part4}
                          />
                        </div>
                      </div>
                      <div className={styled.inp_name_body_about}>
                        <p
                          style={
                            mode ? { color: "#253858" } : { color: "#264e93" }
                          }
                          className={styled.exam_name}
                        >
                          Əlavə
                        </p>
                        <div
                          style={
                            mode ? { color: "#253858" } : { color: "white" }
                          }
                          className={styled.inp_body}
                        >
                          <BsInfoCircle />
                          <textarea
                            style={
                              mode ? { color: "#253858" } : { color: "white" }
                            }
                            id="part5"
                            name="part5"
                            type="text"
                            placeholder="Ətraflı məlumat yazın"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.part5}
                          />
                        </div>
                      </div>
                      <div className={styled.inp_name_body_about}>
                        <p
                          style={
                            mode ? { color: "#253858" } : { color: "#264e93" }
                          }
                          className={styled.exam_name}
                        >
                          Əlavə
                        </p>
                        <div
                          style={
                            mode ? { color: "#253858" } : { color: "white" }
                          }
                          className={styled.inp_body}
                        >
                          <BsInfoCircle />
                          <textarea
                            style={
                              mode ? { color: "#253858" } : { color: "white" }
                            }
                            id="part6"
                            name="part6"
                            type="text"
                            placeholder="Ətraflı məlumat yazın"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.part6}
                          />
                        </div>
                      </div>
                      <div className={styled.inp_name_body_about}>
                        <p
                          style={
                            mode ? { color: "#253858" } : { color: "#264e93" }
                          }
                          className={styled.exam_name}
                        >
                          Əlavə
                        </p>
                        <div
                          style={
                            mode ? { color: "#253858" } : { color: "white" }
                          }
                          className={styled.inp_body}
                        >
                          <BsInfoCircle />
                          <textarea
                            style={
                              mode ? { color: "#253858" } : { color: "white" }
                            }
                            id="part7"
                            name="part7"
                            type="text"
                            placeholder="Ətraflı məlumat yazın"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.part7}
                          />
                        </div>
                      </div>
                      <div className={styled.inp_name_body_about}>
                        <p
                          style={
                            mode ? { color: "#253858" } : { color: "#264e93" }
                          }
                          className={styled.exam_name}
                        >
                          Əlavə
                        </p>
                        <div
                          style={
                            mode ? { color: "#253858" } : { color: "white" }
                          }
                          className={styled.inp_body}
                        >
                          <BsInfoCircle />
                          <textarea
                            style={
                              mode ? { color: "#253858" } : { color: "white" }
                            }
                            id="part8"
                            name="part8"
                            type="text"
                            placeholder="Ətraflı məlumat yazın"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.part8}
                          />
                        </div>
                      </div>
                      <div className={styled.inp_name_body_about}>
                        <p
                          style={
                            mode ? { color: "#253858" } : { color: "#264e93" }
                          }
                          className={styled.exam_name}
                        >
                          Əlavə
                        </p>
                        <div
                          style={
                            mode ? { color: "#253858" } : { color: "white" }
                          }
                          className={styled.inp_body}
                        >
                          <BsInfoCircle />
                          <textarea
                            style={
                              mode ? { color: "#253858" } : { color: "white" }
                            }
                            id="part9"
                            name="part9"
                            type="text"
                            placeholder="Ətraflı məlumat yazın"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.part9}
                          />
                        </div>
                      </div>
                      <div className={styled.inp_name_body_about}>
                        <p
                          style={
                            mode ? { color: "#253858" } : { color: "#264e93" }
                          }
                          className={styled.exam_name}
                        >
                          Əlavə
                        </p>
                        <div
                          style={
                            mode ? { color: "#253858" } : { color: "white" }
                          }
                          className={styled.inp_body}
                        >
                          <BsInfoCircle />
                          <textarea
                            style={
                              mode ? { color: "#253858" } : { color: "white" }
                            }
                            id="part10"
                            name="part10"
                            type="text"
                            placeholder="Ətraflı məlumat yazın"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.part10}
                          />
                        </div>
                      </div>
                      <div className={styled.inp_name_body_about}>
                        <p
                          style={
                            mode ? { color: "#253858" } : { color: "#264e93" }
                          }
                          className={styled.exam_name}
                        >
                          Əlavə
                        </p>
                        <div
                          style={
                            mode ? { color: "#253858" } : { color: "white" }
                          }
                          className={styled.inp_body}
                        >
                          <BsInfoCircle />
                          <textarea
                            style={
                              mode ? { color: "#253858" } : { color: "white" }
                            }
                            id="part11"
                            name="part11"
                            type="text"
                            placeholder="Ətraflı məlumat yazın"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.part11}
                          />
                        </div>
                      </div>
                      <div className={styled.inp_name_body_about}>
                        <p
                          style={
                            mode ? { color: "#253858" } : { color: "#264e93" }
                          }
                          className={styled.exam_name}
                        >
                          Əlavə
                        </p>
                        <div
                          style={
                            mode ? { color: "#253858" } : { color: "white" }
                          }
                          className={styled.inp_body}
                        >
                          <BsInfoCircle />
                          <textarea
                            style={
                              mode ? { color: "#253858" } : { color: "white" }
                            }
                            id="part12"
                            name="part12"
                            type="text"
                            placeholder="Ətraflı məlumat yazın"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.part12}
                          />
                        </div>
                      </div>
                      <div className={styled.inp_name_body_about}>
                        <p
                          style={
                            mode ? { color: "#253858" } : { color: "#264e93" }
                          }
                          className={styled.exam_name}
                        >
                          Xüsusi Qeyd
                        </p>
                        <div
                          style={
                            mode ? { color: "#253858" } : { color: "white" }
                          }
                          className={styled.inp_body}
                        >
                          <BsInfoCircle />
                          <textarea
                            style={
                              mode ? { color: "#253858" } : { color: "white" }
                            }
                            id="special"
                            name="special"
                            type="text"
                            placeholder="Ətraflı məlumat yazın"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.special}
                          />
                        </div>
                      </div>

                      <div className={styled.inp_name_body}>
                        <p
                          style={
                            mode ? { color: "#253858" } : { color: "#264e93" }
                          }
                          className={styled.exam_name}
                        >
                          Əmək haqqı
                        </p>
                        <div
                          style={
                            mode ? { color: "#253858" } : { color: "white" }
                          }
                          className={styled.inp_body}
                        >
                          <RiMoneyEuroCircleLine />
                          <input
                            style={
                              mode ? { color: "#253858" } : { color: "white" }
                            }
                            id="salary"
                            name="salary"
                            type="text"
                            placeholder="Əmək haqqını daxil edin"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.salary}
                          />
                        </div>
                        {formik.touched.salary && formik.errors.salary ? (
                          <div className={styled.require}>
                            {formik.errors.salary}
                          </div>
                        ) : null}
                      </div>
                      <div className={styled.inp_name_body}>
                        <p
                          style={
                            mode ? { color: "#253858" } : { color: "#264e93" }
                          }
                          className={styled.exam_name}
                        >
                          Ünvan
                        </p>
                        <div
                          style={
                            mode ? { color: "#253858" } : { color: "white" }
                          }
                          className={styled.inp_body}
                        >
                          <MdOutlineWrongLocation />
                          <input
                            style={
                              mode ? { color: "#253858" } : { color: "white" }
                            }
                            id="location"
                            name="location"
                            type="text"
                            placeholder="Ünvanı daxil edin"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.location}
                          />
                        </div>
                        {formik.touched.location && formik.errors.location ? (
                          <div className={styled.require}>
                            {formik.errors.location}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={styled.addExam_btns}>
                      <button
                        className={styled.cancelBtn}
                        type="button"
                        onClick={() => navigate("/admin/exams")}
                      >
                        Ləğv et
                      </button>
                      <button className={styled.addBtn} type="submit">
                        Əlavə et
                      </button>
                    </div>
                  </div>
                </TabPane>
                {/* {params.id && (
                <TabPane tab="Şirkətin işləri" key="2">
                  <div className={styled.qustion_header}>
                    <button
                     style={mode? {border:'2px solid  #253858',color:" #253858"} :{border: "2px solid #264e93",color: "#264e93"}}
                      type="button"
                      className={styled.add_question_btn}
                      onClick={() => setShowAddEditQuestionModal(true)}
                    >
                      <i className="ri-add-line"></i>
                      <span>İş əlavə et</span>
                    </button>
                  </div>
                  <div className={styled.table}>
                  <Table
                    columns={questionColumns}
                    dataSource={examData?.questions || []}
                  />
                  </div>
                </TabPane>
              )} */}
              </Tabs>
            </form>
          )}
        </div>
        {showAddEditQuestionModal && (
          <AddEditQuestion
            setShowAddEditQuestionModal={setShowAddEditQuestionModal}
            showAddEditQuestionModal={showAddEditQuestionModal}
            examId={params.id}
            refreshData={getExamData}
            selcetedQuestion={selcetedQuestion}
            setSelcetedQuestion={setSelcetedQuestion}
          />
        )}
      </div>
    </div>
  );
};

export default AddEditExams;
