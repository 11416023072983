import React, { useEffect, useRef, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import HomeHeader from "../../components/HomeHeader";
import styled from "./style.module.scss";
import Carusel from "../../components/Carusel";
import HomeFooter from "../../components/HomeFooter";
import { getAllQuestion, getAllQuestionEn } from "../../apicalls/exmas";
import { message } from "antd";
import { IoMdAdd } from "react-icons/io";
import { BsTelegram } from "react-icons/bs";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdOutlineExpandMore } from "react-icons/md"; //pul
import { useNavigate } from "react-router-dom";
import imageSectionOne from "../.././asset/euroImage/1.jpg";
import imageSectionTwo from "../.././asset/euroImage/Euro.jpg";
import { useTranslation } from "react-i18next";
import ReactWhatsapp from "react-whatsapp";
const Home = () => {
  const navigate = useNavigate();
  const [exams, setExams] = useState([]);
  const [sortByDate, setSortByDate] = useState("newest");
  const [sortByPrice, setSortByPrice] = useState("lowToHigh");
  const [jobLocation, setJobLocation] = useState("");
  const { t } = useTranslation();

  const handleChangeLocation = (event) => {
    setJobLocation(event.target.value);
  };
  exams.filter((data) => {
    if (jobLocation === "") {
      return data;
    } else if (
      data.name.toLowerCase().includes(jobLocation.toLocaleLowerCase())
    ) {
      return data;
    }
  });
  const slectorvaluHome = localStorage.getItem("i18nextLng");

  const getExams = async () => {
    try {
      // dispatch(ShowLoading());
      var response;
      if (slectorvaluHome === "az") {
        response = await getAllQuestion();
        if (response && response.success) {
          let sorted = [...response.data];

          // For Date Sort
          if (sortByDate === "newest") {
            sorted.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            );
          } else {
            sorted.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            );
          }

          // For Salary Sort
          if (sortByPrice === "lowToHigh") {
            sorted.sort((a, b) => parseInt(a.salary) - parseInt(b.salary));
          } else {
            sorted.sort((a, b) => parseInt(b.salary) - parseInt(a.salary));
          }

          setExams(sorted);
        } else {
          message.error(response.message);
        }
      } else if (slectorvaluHome === "en") {
        response = await getAllQuestionEn();
        if (response && response.success) {
          let sorted = [...response.data];

          // For Date Sort
          if (sortByDate === "newest") {
            sorted.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            );
          } else {
            sorted.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            );
          }

          // For Salary Sort
          if (sortByPrice === "lowToHigh") {
            sorted.sort((a, b) => parseInt(a.salary) - parseInt(b.salary));
          } else {
            sorted.sort((a, b) => parseInt(b.salary) - parseInt(a.salary));
          }

          setExams(sorted);
        } else {
          message.error(response.message);
        }
      }

      // dispatch(HideLoading());
    } catch (error) {
      // dispatch(HideLoading());
      message.error(error.message);
    }
  };
  useEffect(() => {
    getExams();
  }, [sortByDate, sortByPrice]);
  const getJobDetail = (id) => {
    navigate(`/detail/${id}`);
  };
  const [jobName, setJobName] = useState(""); // İş adı arama terimi
  const [location, setLocation] = useState(""); // Lokasyon arama terimi
  const [companyName, setCompanyName] = useState(""); // Şirket adı arama terimi
  const [visibleDataCount, setVisibleDataCount] = useState(8);
  const handleJobNameSearch = (event) => {
    setJobName(event.target.value);
  };

  const handleLocationSearch = (event) => {
    setLocation(event.target.value);
  };

  const handleCompanyNameSearch = (event) => {
    setCompanyName(event.target.value);
  };
  const handleSearchButtonClick = () => {
    // Arama işlemleri yapabilirsiniz...
    // Bu kısımda inputlardan alınan değerleri kullanarak arama işlemi gerçekleştirilebilir.

    // Input değerlerini sıfırlayalım
    setJobName("");
    setLocation("");
    setCompanyName("");
  };

  const filteredData =
    exams &&
    exams.filter((data) => {
      return (
        data.name.toLowerCase().includes(jobName.toLowerCase()) &&
        data.location.toLowerCase().includes(location.toLowerCase())
      );
    });

  const displayData = jobName || location || companyName ? filteredData : exams;
  const totalDataCount = displayData.length;

  const loadMoreData = () => {
    setVisibleDataCount((prevCount) => prevCount + 8);
  };
  const jobSectionRef = useRef(null);

  const handleAraButtonClick = () => {
    if (jobSectionRef.current) {
      jobSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // const handleSelect = (date) => {
  //   let filtered = exams.filter((exam) => {
  //     let examDate = new Date(exam.createdAt);
  //     return (examDate >= date.selection.startDate && examDate <= date.selection.endDate);
  //   });

  //   setSortByDate(''); // Bu satırı ekleyerek, date range seçimi değiştiğinde sortByDate'i sıfırlıyoruz.
  //   setExams(filtered);
  // };

  const handleSortByDateChange = (e) => {
    setSortByDate(e.target.value);
  };
  const handleSortByPriceChange = (e) => {
    setSortByPrice(e.target.value);
  };

  return (
    <div>
      <section>
        <HomeHeader />
        <div className={styled.header}>
          <div className={styled.header_right}>
            <div className={styled.header_text}>
              <h2>{t("homeHeader.h1")}</h2>
              <p>{t("homeHeader.p")}</p>
              <div className={styled.searchPanel}>
                <div className={styled.search_input}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M19.7722 18.6726L14.8912 13.7917C16.1044 12.3328 16.8354 10.4593 16.8354 8.41797C16.8354 3.77628 13.0592 0 8.41774 0C3.77617 0 0 3.77628 0 8.41797C0 13.0593 3.77617 16.8353 8.41774 16.8353C10.459 16.8353 12.3325 16.1045 13.7915 14.8912L18.6725 19.7722C18.8243 19.9241 19.0234 20 19.2224 20C19.4213 20 19.6204 19.9241 19.7723 19.7722C20.0759 19.4685 20.0759 18.9763 19.7722 18.6726ZM1.55517 8.41797C1.55517 4.63381 4.63369 1.55518 8.41774 1.55518C12.2017 1.55518 15.2801 4.63381 15.2801 8.41797C15.2801 12.2018 12.2017 15.2801 8.41774 15.2801C4.63369 15.2801 1.55517 12.2018 1.55517 8.41797Z"
                      fill="#696969"
                    />
                  </svg>
                  <input
                    value={jobName}
                    onChange={handleJobNameSearch}
                    type="text"
                    placeholder={t("inputPlaceholder.name")}
                  />
                </div>

                <button onClick={handleAraButtonClick}>
                  {t("inputPlaceholder.find")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ReactWhatsapp
        className={styled.whatsapp}
        number="994-55-906-00-46"
        message="Salam,hər vaxtınız xeyir."
        width={"100px"}
        height={"100px"}
      >
        <IoLogoWhatsapp />
      </ReactWhatsapp>

      {/* <section className={styled.container}>
       <div className={styled.video}>
       <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/B90WpO72TrY"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
       </div>
      </section> */}

      <section ref={jobSectionRef} className={styled.jobs_section}>
        <div className={styled.contanier}>
          <div>
            <h2>{t("jobsHader.h1")}</h2>
            <p className={styled.job_section_headp}>{t("jobsHader.p")}</p>
            {displayData && displayData.length > 0 ? (
              <div className={styled.select_body}>
                <div className={styled.select_itemBody}>
                  <p>{t("selection.selec1")}</p>
                  <div className={styled.select}>
                    <select
                      value={sortByDate}
                      onChange={handleSortByDateChange}
                    >
                      <option value="newest">
                        {t("selection.optionDate1")}
                      </option>
                      <option value="oldest">
                        {t("selection.optionDate2")}
                      </option>
                    </select>
                  </div>
                </div>
                <div className={styled.select_itemBody}>
                  <p>{t("selection.selec2")}</p>
                  <div className={styled.select}>
                    <select
                      id="salary"
                      value={sortByPrice}
                      onChange={handleSortByPriceChange}
                    >
                      <option value="lowToHigh">
                        {t("selection.optionSalary2")}
                      </option>
                      <option value="highToLow">
                        {t("selection.optionSalary1")}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}

            <div className={styled.cards_body}>
            
              {displayData && displayData.length > 0 ? (
                displayData.slice(0, visibleDataCount).map((data) => {
                  return (
                    <div key={data._id} className={styled.card}>
                      <img
                        className={styled.card_jobImage}
                        src={data.category}
                        alt="job Image"
                      />
                      <div>
                        <p className={styled.card_companyName}>
                          {data.compName}
                        </p>
                        <p className={styled.card_jobTitle}>{data.name}</p>
                      </div>
                      {/* <p className={styled.card_jobLocation}>
                        <CiLocationOn />
                        {data.location}
                      </p> */}
                      <p className={styled.card_jobLocation}>
                        {/* <RiMoneyEuroCircleLine /> */}
                        <strong>{t("hourSalary")}</strong>
                        {data.salary} ₼
                      </p>
                      <button onClick={() => getJobDetail(data._id)}>
                        <IoMdAdd />
                        <p>{t("apply.btn")}</p>
                      </button>
                    </div>
                  );
                })
              ) : (
                <div className={styled.emptyData_body}>
                  {/* <img src={emptyData}/> */}
                  <p>{t("noResult")}</p>
                </div>
              )}
            </div>
            <div className={styled.loadMore_body}>
              {displayData.length > 0 ? (
                displayData.length > 7 ? (
                  <button onClick={loadMoreData}>
                    <MdOutlineExpandMore />
                    <p>{t("apply.btn3")}</p>
                  </button>
                ) : 
                null
              ) : (
                
                <button onClick={handleSearchButtonClick}>
                  <p>{t("apply.btn2")}</p>
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <section id="learning">
        <div className={styled.learning_section}>
          <div className={styled.contanier}>
            <div className={styled.learning_section_left}>
              <h2>İndi EuroVacancy-də işə götürən ən yaxşı şirkətlər</h2>

            </div>
            <div className={styled.learning_section_right}>
              <img src={image1} />
              <img src={image2} />
              <img src={image3} />
              <img src={image4} />
              <img src={image5} />
              <img src={image6} />
              <img src={image5} />
            </div>
          </div>
        </div>
      </section> */}
      <section id="accesibility">
        <div className={styled.activity_section}>
          <div className={styled.activity_section_right}>
            <img src={imageSectionOne} />
          </div>

          <div className={styled.activity_section_left}>
            <div>
              <h2>{t("apply.h1")}</h2>
              <p>{t("apply.text")}</p>
              <button onClick={() => navigate("/apply")}>
                <IoMdAdd />
                {t("apply.btn")}
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* <section id="accesibility">
        <div className={styled.activity_section_left_body}>
          <div className={styled.activity_section_left}>
            <div>
              <h2>I am Recruiter</h2>
              <p>
                One of our One of our jobs has some kind of flexibility jobs has
                some kind of flexibility option such as telecommuting, a
                part-time schedule or a flexible or flextime.
              </p>
              <button onClick={()=> window.open('http://t.me/kanans')}>
                <BsTelegram />
                Telegramda bizə qoşul
              </button>
            </div>
          </div>
          <div className={styled.activity_section_right}>
            <img src="https://mykaleidoscope.ru/x/uploads/posts/2022-10/1666235211_15-mykaleidoscope-ru-p-uvlekayushchiisya-chelovek-oboi-19.jpg" />
          </div>
        </div>
      </section> */}
      <section id="accesibility">
        <div className={styled.activity_section2}>
          <div className={styled.activity_section_right}>
            <img src={imageSectionTwo} />
          </div>

          <div className={styled.activity_section_left}>
            <div>
              <h2>{t("joinUs.h1")}</h2>
              <p>{t("joinUs.text")}</p>
              <button onClick={() => window.open("https://t.me/itacaz")}>
                <BsTelegram />
                {t("joinUs.btn")}
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* <section className={styled.company_section}>
        <div className={styled.container}>
          <h2 className={styled.companyHeader}>Top Company Registered</h2>
          <h2 className={styled.companyParagraph}>
            Some of the companies we've helped recruit excellent applicants over
            the years.
          </h2>
          <div className={styled.cards_body}>
            <div className={styled.card}>
              <div className={styled.card_left}>
                <img src="https://w.forfun.com/fetch/cc/cc6760a4d3682a851101bffc5616510d.jpeg" />
                <div className={styled.card_left_text}>
                  <h2>Udemy</h2>
                  <p>
                    <CiLocationOn /> London
                  </p>
                </div>
              </div>
              <div className={styled.card_right}>15 Open Position</div>
            </div>
            <div className={styled.card}>
              <div className={styled.card_left}>
                <img src="https://w.forfun.com/fetch/cc/cc6760a4d3682a851101bffc5616510d.jpeg" />
                <div className={styled.card_left_text}>
                  <h2>Udemy</h2>
                  <p>
                    <CiLocationOn /> London
                  </p>
                </div>
              </div>
              <div className={styled.card_right}>15 Open Position</div>
            </div>
            <div className={styled.card}>
              <div className={styled.card_left}>
                <img src="https://w.forfun.com/fetch/cc/cc6760a4d3682a851101bffc5616510d.jpeg" />
                <div className={styled.card_left_text}>
                  <h2>Udemy</h2>
                  <p>
                    <CiLocationOn /> London
                  </p>
                </div>
              </div>
              <div className={styled.card_right}>15 Open Position</div>
            </div>
            <div className={styled.card}>
              <div className={styled.card_left}>
                <img src="https://w.forfun.com/fetch/cc/cc6760a4d3682a851101bffc5616510d.jpeg" />
                <div className={styled.card_left_text}>
                  <h2>Udemy</h2>
                  <p>
                    <CiLocationOn /> London
                  </p>
                </div>
              </div>
              <div className={styled.card_right}>15 Open Position</div>
            </div>
          </div>
        </div>
      </section> */}

      <section id="activity">
        <div className={styled.carusel_body}>
          <Carusel />
        </div>
      </section>

      {/* <section id="contact">
         <Contact/>
      </section> */}

      <section>
        <HomeFooter />
      </section>
    </div>
  );
};
export default Home;
