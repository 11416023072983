import React, { useEffect } from 'react'
import { Modal, message } from 'antd'
import styled from './style.module.scss'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addQuestionToExam, editQuestionById } from '../../../../apicalls/exmas';
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from '../../../../redux/loaderSlice/loaderSlice';
const AddEditQuestion = ({
  showAddEditQuestionModal,
  setShowAddEditQuestionModal,
  refreshData,
  examId,
  selcetedQuestion,
  setSelcetedQuestion
}) => {

  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues: {
      name: '',
      correctOption: '',
      salary:'',
      loaction:''
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, 'Minimum 2 simvol olmalidir')
        .required('*Bu xana boş qala bilməz!'),
      correctOption: Yup.string()
        .min(10, 'minumum 10 simvol olmalidir')
        .required('*Bu xana boş qala bilməz!'),
      salary: Yup.string()
        .min(1, 'Minimum 1 simvol olmalidir')
        .required('*Bu xana boş qala bilməz!'),
      loaction: Yup.string()
        .min(5, 'Minimum 5 simvol olmalidir')
        .required('*Bu xana boş qala bilməz!'),
      
    }),
    onSubmit: async (values) => {
      try {
        dispatch(ShowLoading());
        const requiredPayload = {
          name: values.name,
          correctOption: values.correctOption,
          salary: values.salary,
          loaction: values.loaction,
          exam: examId,
        };

        let response;
         if(selcetedQuestion) {
            response = await editQuestionById({
              ... requiredPayload,
              questionId: selcetedQuestion._id
            })
         }else{
         response = await addQuestionToExam(requiredPayload);
         }
        if (response.success) {
          message.success(response.message);
          refreshData();
          setShowAddEditQuestionModal(false);
        } else {
          message.error(response.message);
        }
        setSelcetedQuestion(null)
        dispatch(HideLoading());
      } catch (error) {
        dispatch(HideLoading());
        message.error(error.message);
      }
    },
  });
  useEffect(() => {
    if (selcetedQuestion) {
      formik.setValues({
        name: selcetedQuestion?.name,
        correctOption: selcetedQuestion?.correctOption,
        salary: selcetedQuestion?.salary,
        loaction: selcetedQuestion?.loaction,
      });
    }
  }, [selcetedQuestion]);
  return (
    <>
      <Modal className={styled.module_first} title={selcetedQuestion? "İşi düzəlt" : 'İş Əlavə et' }visible={showAddEditQuestionModal} footer={true} onCancel={() => {setShowAddEditQuestionModal(false)
      setSelcetedQuestion(null)
      }}>
        <form onSubmit={formik.handleSubmit}>
          <div className={styled.inp_name_body}>
            <p className={styled.exam_name}>İşin adı</p>
            <div className={styled.inp_body}>
              <input
                id="name"
                name="name"
                type="text"
                placeholder='İşin adını daxil edin.'

                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
            </div>
            {formik.touched.name && formik.errors.name ? (
              <div className={styled.require}>{formik.errors.name}</div>
            ) : null}
          </div>

          <div className={styled.inp_name_body_about}>
            <p className={styled.exam_name}>İş haqqında</p>
            <div className={styled.inp_body}>
              <input
                id="correctOption"
                name="correctOption"
                type="text"
                placeholder='iş ayrıntısını daxil edin.'

                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.correctOption}
              />
            </div>
            {formik.touched.correctOption && formik.errors.correctOption ? (
              <div className={styled.require}>{formik.errors.correctOption}</div>
            ) : null}
          </div>
          <div className={styled.inp_name_body}>
            <p className={styled.exam_name}>Əmək haqqı</p>
            <div className={styled.inp_body}>
              <input
                id="salary"
                name="salary"
                type="number"
                placeholder='Əmək haqqını daxil edin.'

                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.salary}
              />
            </div>
            {formik.touched.salary && formik.errors.salary ? (
              <div className={styled.require}>{formik.errors.salary}</div>
            ) : null}
          </div>
          <div className={styled.inp_name_body}>
            <p className={styled.exam_name}>Ünvan</p>
            <div className={styled.inp_body}>
              <input
                id="loaction"
                name="loaction"
                type="text"
                placeholder='Ünvanı daxil edin.'

                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.loaction}
              />
            </div>
            {formik.touched.loaction && formik.errors.loaction ? (
              <div className={styled.require}>{formik.errors.loaction}</div>
            ) : null}
          </div>

         
          
          <div className={styled.btns}>
            <button className={styled.cancelBtn} type='button' onClick={() => setShowAddEditQuestionModal(false)}>Ləğv et</button>
            <button className={styled.addBtn} type="submit">Əlavə et</button>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default AddEditQuestion