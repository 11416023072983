import React, { useEffect, useRef, useState } from 'react'
import HomeHeader from '../../components/HomeHeader'
import HomeFooter from '../../components/HomeFooter'
import styled from './style.module.scss'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import emailjs from '@emailjs/browser';
import { useFormik   } from "formik";
import * as Yup from "yup";
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
const Apply = () => {
  const [showCreateCVModal, setShowCreateCVModal] = useState(false);
  const form = useRef();
  const { t } = useTranslation();
  
  const navigate = useNavigate()
  const handleMüraciətEtClick = () => {
    Swal.fire({
      title: t("applyCv.buttons.apply"),
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      denyButtonColor: '#3085d6',
      confirmButtonText: t("detail.buttons.apply"),
      denyButtonText: t("detail.buttons.creatCv"),
      cancelButtonText: t("detail.buttons.close"),
      confirmButtonText: t("detail.buttons.creatCv"),
      denyButtonText: t("detail.buttons.uploadCv"),
      cancelButtonText: t("detail.buttons.close"),
    }).then((result) => {
      if (result.isConfirmed) {
        setShowCreateCVModal(true);
      } else if (result.isDenied) {
        
      const recipientEmail = "itacazerbaycan@gmail.com"; // Alıcı e-posta adresi
      const subject = `İş müraciəti`; // E-posta konusu
      const body = `KUrsun adı: \nİstədiyiniz ölkə: \nCvni bura yerləşdirin`; // E-posta içeriği
  
      // Gmail'e yönlendirmek için URL oluşturun
      const gmailUrl = `https://mail.google.com/mail/?view=cm&to=${recipientEmail}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  
      // Gmail'e yönlendirin
      window.open(gmailUrl);
      }
      else{
          navigate('/')
      }
    });
  };

  useEffect(()=>{
    window.scrollTo(0, 0);
    const alertTimer = setTimeout(()=>{
      handleMüraciətEtClick()
    },100)
    return () => clearTimeout(alertTimer); // Komponent kaldırıldığında, modal zamanlayıcısını temizle
  }, []);

  const formik = useFormik({
    initialValues: {
      name: '',
      location: '',
      userName:'',
      age:'',
      email:'',
      number:'',
      langugage:'',
      useLocation:'',
      education:'',
      experience:'',
      anotherCountry:'',
      aboutUs:'',
    },
    validationSchema: Yup.object({
      name:Yup.string().required("*Bu xananı doldurun!"),
      location:Yup.string().required("*Bu xananı doldurun!"),
      userName: Yup.string()
        .min(5, "Bu bölmə 5 simvoldan az ola bilməz")
        .required("*Bu xananı doldurun!"),
      age: Yup.string()
        .min(2, "Yaş 2 simvoldan az ola bilməz")
        .required("*Bu xananı doldurun!"),
      email:Yup.string().email('keçərsiz email adres'),
      number:Yup.string()
      .required()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(9, 'Minimum rəqəm sayı 9 olmalıdır')
      .max(9, 'Maximum rəqəm sayı 9 olmalıdır')
      .required('*Bu xananı doldurun!'),
      langugage:Yup.string().min(5,'Bu bölmə 5 simvoldan az ola bilməz')
      .required('*Bu xananı doldurun!'),
      useLocation:Yup.string().min(3,'Bu bölmə 3 simvoldan az ola bilməz'),
      education:Yup.string().min(3,'Bu bölmə 3 simvoldan az ola bilməz')
      .required('*Bu xananı doldurun!'),
      experience:Yup.string().min(3,'Bu bölmə 3 simvoldan az ola bilməz')
      .required('*Bu xananı doldurun!'),
  
       
    }),
    onSubmit: async (values) => {
      
      try {
         emailjs.sendForm(
          "service_34bdraj", // Replace with your EmailJS service ID
          "template_424aton", // Replace with your EmailJS template ID
          form.current,
          "HSmKQe0ox9QaVgLWL" // Replace with your EmailJS user ID
        );
    
        // Successful submission
        toast(t("alert2.success"), {
          duration: 4000,
          position: 'top-center',
          icon: '✅',
        });
        formik.resetForm();
        setTimeout(()=> navigate('/'),1200)
      } catch (error) {
        // Submission error
        toast(t("alert2.faild"), {
          duration: 4000,
          position: 'top-center',
          icon: '❌',

        });
      }
    },
  });
  
  return (
    <>
    <HomeHeader/>
    <div className={styled.apply_body}>
    <Modal
        title={t("applyCv.createCvInp.title")}
        centered
        open={showCreateCVModal}
        okText='Create'
        onOk={() => setShowCreateCVModal(false)}
        onCancel={() => navigate('/')}
        footer={null}
        width={1000}
        className={styled.modal}
      >
       <form ref={form} onSubmit={formik.handleSubmit}>
            
                <div className={styled.tab_pane1}>
                  <div className={styled.inp_name_body}>
                    <p  className={styled.exam_name}>
                    {t("applyCv.createCvInp.jobName")}
                    </p>
                    <div className={styled.inp_body}>
                     
                      <input
                       
                        id="name"
                        name="name"
                        type="text"
                        placeholder={t("applyCv.createCvInp.jobNamePlace")}

                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                      />
                    </div>
                    {formik.touched.name && formik.errors.name ? (
                      <div className={styled.require}>
                        {formik.errors.name}
                      </div>
                    ) : null}
                   
                  </div>
                
                <div className={styled.inp_name_body}>
                    <p 
                    
                    className={styled.exam_name}>
                    {t("applyCv.createCvInp.Location")}

                    </p>
                    <div 
                   
                    className={styled.inp_body}>
                      
                      <input
                        
                        id="location"
                        name="location"
                        type="text"
                        placeholder={t("applyCv.createCvInp.LocationPlace")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.location}
                      />
                    </div>
                    {formik.touched.location && formik.errors.location ? (
                      <div className={styled.require}>
                        {formik.errors.location}
                      </div>
                    ) : null}
                    
                </div>
                </div>         
                <div>

                <div className={styled.about_userBody}>

                  <div className={styled.inp_name_body}>
                    <p 
                    
                    className={styled.exam_name}>
                    {t("applyCv.createCvInp.inp1.label")}
                    </p>
                    <div 
                    
                    className={styled.inp_body}>
                      
                      <input
                        id="userName"
                        name="userName"
                        type="text"
                        placeholder={t("applyCv.createCvInp.inp1.placeholder")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.userName}
                      />
                    </div>
                    {formik.touched.userName && formik.errors.userName ? (
                      <div className={styled.require}>
                        {formik.errors.userName}
                      </div>
                    ) : null}
                  </div>
             
                <div className={styled.inp_name_body}>
                    <p 
                     
                    className={styled.exam_name}>
                    {t("applyCv.createCvInp.inp2.label")}
                    </p>
                    <div 
                  
                    className={styled.inp_body}>
                      <input
                      
                        id="age"
                        name="age"
                        type="number"
                        placeholder={t("applyCv.createCvInp.inp2.placeholder")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.age}
                      />
                    </div>
                    {formik.touched.age && formik.errors.age ? (
                      <div className={styled.require}>
                        {formik.errors.age}
                      </div>
                    ) : null}
                  </div>
                <div className={styled.inp_name_body}>
                    <p 
                     
                    className={styled.exam_name}>
                    {t("applyCv.createCvInp.inp3.label")}
                    </p>
                    <div 
                  
                    className={styled.inp_body}>
                      <input
                      
                        id="email"
                        name="email"
                        type="email"
                        placeholder={t("applyCv.createCvInp.inp3.placeholder")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                    </div>
                    {formik.touched.email && formik.errors.email ? (
                      <div className={styled.require}>
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </div>
                <div className={styled.inp_name_body}>
                    <p 
                     
                    className={styled.exam_name}>
                    {t("applyCv.createCvInp.inp4.label")}
                    </p>
                    <div 
                    
                    className={styled.inp_body}>
                     (+994)
                      <input
                      
                        id="number"
                        name="number"
                        type="number"
                        placeholder={t("applyCv.createCvInp.inp4.placeholder")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.number}
                      />
                    </div>
                    {formik.touched.number && formik.errors.number ? (
                      <div className={styled.require}>
                        {formik.errors.number}
                      </div>
                    ) : null}
                  </div>
                <div className={styled.inp_name_body}>
                    <p 
                     
                    className={styled.exam_name}>
                    {t("applyCv.createCvInp.inp5.label")}
                    </p>
                    <div 
                  
                    className={styled.inp_body}>
                      <input
                      
                        id="langugage"
                        name="langugage"
                        type="text"
                        placeholder={t("applyCv.createCvInp.inp5.placeholder")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.langugage}
                      />
                    </div>
                    {formik.touched.langugage && formik.errors.langugage ? (
                      <div className={styled.require}>
                        {formik.errors.langugage}
                      </div>
                    ) : null}
                  </div>
                <div className={styled.inp_name_body}>
                    <p 
                     
                    className={styled.exam_name}>
                    {t("applyCv.createCvInp.inp6.label")}
                    </p>
                    <div 
                  
                    className={styled.inp_body}>
                      <input
                      
                        id="useLocation"
                        name="useLocation"
                        type="text"
                        placeholder={t("applyCv.createCvInp.inp6.placeholder")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.useLocation}
                      />
                    </div>
                    {formik.touched.useLocation && formik.errors.useLocation ? (
                      <div className={styled.require}>
                        {formik.errors.useLocation}
                      </div>
                    ) : null}
                  </div>
                <div className={styled.textArea_name_body}>
                    <p 
                     
                    className={styled.exam_name}>
                    {t("applyCv.createCvInp.inp7.label")}
                    </p>
                    <div 
                  
                    className={styled.inp_body}>
                      <textarea
                      
                        id="education"
                        name="education"
                        type="text"
                        placeholder={t("applyCv.createCvInp.inp7.placeholder")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.education}
                      />
                    </div>
                    {formik.touched.education && formik.errors.education ? (
                      <div className={styled.require}>
                        {formik.errors.education}
                      </div>
                    ) : null}
                  </div>
                <div className={styled.textArea_name_body}>
                    <p 
                     
                    className={styled.exam_name}>
                   {t("applyCv.createCvInp.inp8.label")}
                    </p>
                    <div 
                  
                    className={styled.inp_body}>
                      <textarea
                      
                        id="experience"
                        name="experience"
                        type="text"
                        placeholder={t("applyCv.createCvInp.inp8.placeholder")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.experience}
                      />
                    </div>
                    {formik.touched.experience && formik.errors.experience ? (
                      <div className={styled.require}>
                        {formik.errors.experience}
                      </div>
                    ) : null}
                  </div>
                  <div className={styled.inp_name_body}>
                    <p 
                     
                    className={styled.exam_name}>
                    {t("applyCv.createCvInp.inp9.label")}

                    </p>
                    <div 
                  
                    className={styled.inp_body}>
                      <input
                      
                        id="anotherCountry"
                        name="anotherCountry"
                        type="text"
                        placeholder={t("applyCv.createCvInp.inp9.placeholder")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.anotherCountry}
                      />
                    </div>
                    {formik.touched.anotherCountry && formik.errors.anotherCountry ? (
                      <div className={styled.require}>
                        {formik.errors.anotherCountry}
                      </div>
                    ) : null}
                  </div>
                  <div className={styled.inp_name_body}>
                    <p 
                     
                    className={styled.exam_name}>
                   {t("applyCv.createCvInp.inp10.label")}
                    </p>
                    <div 
                  
                    className={styled.inp_body}>
                      <input
                      
                        id="aboutUs"
                        name="aboutUs"
                        type="text"
                        placeholder={t("applyCv.createCvInp.inp10.placeholder")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.aboutUs}
                      />
                    </div>
                    {formik.touched.aboutUs && formik.errors.aboutUs ? (
                      <div className={styled.require}>
                        {formik.errors.aboutUs}
                      </div>
                    ) : null}
                  </div>
                </div>

                </div>
                <div className={styled.sumbitBtnBody}>
                <button className={styled.formSumbit} type="submit">{t("applyCv.createCvInp.btn")}</button>
                <button onClick={() => navigate('/')} className={styled.formSumbitClose} type="button">{t("applyCv.createCvInp.btnClose")}</button>
                </div>
          </form>
      </Modal>
      
    </div>
    <HomeFooter/>
    </>
  )
}

export default Apply